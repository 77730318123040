import { useEffect } from "react";
import {
  Outlet,
  useTheme,
  AppBar,
  Box,
  CssBaseline,
  Toolbar,
  useNavigate,
} from "../../collections/Imports";
import Header from "./Header";
import SideBar from "./Sidebar/SideBar";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  useEffect(() => {
    const handleLoad = () => {
      const nextDayTime = localStorage.getItem("nextDate");
      const timeString = new Date().getTime();
      if (nextDayTime) {
        if (Number(nextDayTime) < Number(timeString)) {
          localStorage.clear();
          navigate("/login");
        }
      }
    };
    handleLoad();
    window.addEventListener("load", handleLoad);

    window.addEventListener("visibilitychange", handleLoad);
    return () => {
      window.removeEventListener("load", handleLoad);

      window.removeEventListener("visibilitychange", handleLoad);
    };
  }, [window.location.href]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* <AppBar
        position="fixed"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
        }}
      >
        <Toolbar> */}
      <Box width={"100%"}>
        <Header />
        <Box
          sx={{
            width: "100%",
            display: "inline-flex",
            marginTop: "3rem",
          }}
          id="rrrr"
        >
          <Box width={"11%"}>
            <SideBar />
          </Box>
          <Box width={"89%"} sx={{ backgroundColor: "#eef2f6" }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
      {/* </Toolbar>
      </AppBar> */}
    </Box>
  );
};

export default MainLayout;
