import { createRoot } from "react-dom/client";
import "./index.css";
import App from "App";
import "assets/scss/style.scss";
import { ApiProvider } from "@reduxjs/toolkit/dist/query/react";
import { apiQueryServices } from "helpers/apiQueryServices";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ApiProvider api={apiQueryServices}>
    <App />
  </ApiProvider>
);
