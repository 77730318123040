import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { router } from "routes";
import themes from "themes";
import "./App.css";
import { SnackbarProvider } from "notistack";
import { DashBoardContextProvider } from "context/dashboardModule";
import { TabProvider } from "context/TabContext";

const App = () => {
  return (
    <DashBoardContextProvider>
      <ThemeProvider theme={themes()}>
        <TabProvider>
          <SnackbarProvider autoHideDuration={3000} dense action>
            <CssBaseline />
            {/* Ensure this component does not contain a Router */}
            {/* <NavigationScroll /> */}
            <RouterProvider router={router} />
          </SnackbarProvider>
        </TabProvider>
      </ThemeProvider>
    </DashBoardContextProvider>
  );
};

export default App;
