import * as React from "react";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import { useSelector } from "react-redux";
// import {
//   Box,
//   Button,
//   Divider,
//   IconButton,
//   CachedOutlined,
//   Stack,
//   Tooltip,
//   Typography,
//   useLocation,
//   TextField,
//   FilterAlt,
//   CircularLoadingProgress,
//   ExportIcon,
// } from "../../../collections/Imports";
import "./style.css";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// import MultiCheckboxFilter from "Components/ui-component/commonFilterCheckbox";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { ExportIcon } from "collections/Imports";
import { CachedOutlined } from "@mui/icons-material";
import { border, borderRadius, padding } from "@mui/system";

function CommonChart({
  title,
  subtitle,
  children,
  buttonValue1,
  onClick,
  icon1,
  buttons,
  searchText,
  renderAutocompletes,
  onSearchChange,
  isFullscreen,
  bgColor,
  TextColor,
  addActionIcon,
  addAction,
  addAction1,
  AddButtonName,
  onToggleFullscreen,
  onRefresh,
  createButtonValue,
  createIcon,
  createOnClick,
  createBgColor,
  backButtonValue,
  rowData,
  columnDefs,
  isGridRequired,
  isLoading,
  pinnedBottomRowData,
  isFilterColumn,
  isServerPagination,
  exportModuleName,
}) {
  // const [permissions, setPermissions] = useState({});
  const [tableRowData, setTableRowData] = useState([]);
  const [tableColumnData, setTableColumnData] = useState([]);

  const gridRef = useRef();

  // const userPermission = useSelector(
  //   (state) => state.permissionsSlice.moduleVisePermissions
  // );

  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   if (location.pathname) {
  //     const currentRoutePermissions = userPermission.find((res) =>
  //       location.pathname.includes(res.route)
  //     );
  //     setPermissions(currentRoutePermissions);
  //   }
  // }, [location]);

  useEffect(() => {
    if (columnDefs && columnDefs.length) {
      setTableColumnData(columnDefs);
    }
    if (rowData && rowData.length) {
      setTableRowData(rowData);
    }
  }, [columnDefs, rowData]);

  const buttonStyles = {
    backgroundColor: `${bgColor ? bgColor : ""}`,
    color: `${TextColor ? TextColor : "#EE9329"}`,
    "&:hover": {
      backgroundColor: "red",
    },
  };

  /////////********///////////

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex: 1,
    defaultSort: "asc",
    cellClass: "my-class",
    minWidth: 200,
    filterParams: {
      buttons: ["apply", "reset"],
      closeOnApply: true,
    },
    cellStyle: { borderRightColor: "#e2e2e2" },
  };

  const onBtnExport = useCallback(() => {
    const gridApi = gridRef?.current?.api;

    const MODULE_NAME = exportModuleName || "export-data";

    if (gridApi) {
      const rowData = gridApi
        .getModel()
        .rowsToDisplay.map((rowNode) => rowNode.data);
      const filteredRows = rowData.filter(
        (row) => !row?.unSubscriberUser === true
      );
      gridApi.exportDataAsCsv({
        skipHeader: false,
        allColumns: false,
        onlySelected: false,
        skipFooters: true,
        fileName: `${MODULE_NAME}.csv`,
        customHeader: null,
        shouldRowBeSkipped: (params) =>
          !filteredRows.includes(params.node.data),
      });
    }
  }, []);

  const onBtShowNoRows = useCallback(() => {
    gridRef.current?.api.showNoRowsOverlay();
  }, []);

  const onBtHide = useCallback(() => {
    gridRef.current?.api.hideOverlay();
  }, []);

  const handleInputChange = (event) => {
    gridRef?.current?.api.setQuickFilter(event.target.value);

    if (
      gridRef?.current?.api?.filterManager?.rowModel?.rowsToDisplay?.length <= 0
    ) {
      onBtShowNoRows();
    } else {
      onBtHide();
    }
  };

  const getRowStyle = useCallback((params) => {
    if (params.node.rowPinned) {
      return { fontWeight: "bold", backgroundColor: "#fff", color: "#000" };
    }
  }, []);

  const handleFilterChange = (selectedValues) => {
    if (selectedValues && selectedValues.length) {
      const col = [];
      for (let item of columnDefs) {
        for (let name of selectedValues) {
          if (name === item.field) {
            col.push(item);
          }
        }
      }
      setTableColumnData(col);
    } else {
      setTableColumnData([]);
    }
  };
  return (
    <Box>
      <Box className="card-background">
        <Stack direction={"row"} sx={{ alignItems: "center" }}>
          <Box
            sx={{
              padding: 2,
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div>
              <Typography
                sx={{ fontWeight: 600, fontSize: { xs: "20px", sm: "12px" } }}
                className="/*  */title-text"
              >
                {title ? title : title}
              </Typography>
              <Typography className="sub-text">
                {subtitle ? subtitle : ""}
              </Typography>
            </div>
            {renderAutocompletes && (
              <Box
                sx={{
                  marginLeft: "2rem",
                  padding: 1,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {renderAutocompletes()}
              </Box>
            )}

            <div>
              <Typography className="sub-text">
                {buttons ? buttons : ""}
              </Typography>
            </div>

            <div>
              <Typography className="sub-text">
                {buttons ? buttons : ""}
              </Typography>
            </div>
          </Box>
          <Box sx={{ flex: 1 }} />

          {onSearchChange && (
            <Box mx={2} style={{ marginTop: 20, marginBottom: 20 }}>
              <label>
                {/* Search:{' '} */}
                <input
                  type="text"
                  value={searchText}
                  className="search-input"
                  onChange={onSearchChange}
                  placeholder="Search..."
                />{" "}
              </label>
            </Box>
          )}

          {isGridRequired && (
            <Box
              mx={2}
              style={{
                marginTop: 20,
                marginBottom: 20,
                border: "0.5px solid #80808045",
                padding: "4px",
                borderRadius: "8px",
              }}
            >
              {/* <label> */}
              {/* Search:{' '} */}
              <TextField
                size="small"
                id="search_Inp"
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: "12px",
                    height: "22px",
                    border: "none",
                    backgroundColor: "unset",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "12px", // Adjust the label font size as needed
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                type="text"
                className="search-input"
                onInput={handleInputChange}
                placeholder="Search..."
              />
              {/* </label> */}
            </Box>
          )}

          {/* add button for setting and add somthing  */}

          {addAction && (
            <Box
            // style={{
            //   cursor:
            //     permissions?.permissions?.writeContent ||
            //     permissions?.permissions?.modifyContent ||
            //     permissions?.permissions?.publishContent ||
            //     permissions?.permissions?.admin
            //       ? "pointer"
            //       : "not-allowed",
            //   opacity:
            //     permissions?.permissions?.writeContent ||
            //     permissions?.permissions?.modifyContent ||
            //     permissions?.permissions?.publishContent ||
            //     permissions?.permissions?.admin
            //       ? 1
            //       : 0.5,
            // }}
            >
              <Button
                startIcon={addActionIcon}
                onClick={addAction}
                // disabled={
                //   !permissions?.permissions?.writeContent &&
                //   !permissions?.permissions?.modifyContent &&
                //   !permissions?.permissions?.publishContent &&
                //   !permissions?.permissions?.admin
                // }
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #DDE1E6",
                  // background: `${
                  //   !permissions?.permissions?.writeContent &&
                  //   !permissions?.permissions?.modifyContent &&
                  //   !permissions?.permissions?.publishContent &&
                  //   !permissions?.permissions?.admin
                  //     ? "#f7f7f7"
                  //     : "#fff"
                  // }`,
                  color: "#EE9329",
                  //  `${
                  //   !permissions?.permissions?.writeContent &&
                  //   !permissions?.permissions?.modifyContent &&
                  //   !permissions?.permissions?.publishContent &&
                  //   !permissions?.permissions?.admin
                  //     ? "gray"
                  //     : "#EE9329"
                  // }`,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  marginRight: "1rem",
                }}
              >
                {AddButtonName}
              </Button>
            </Box>
          )}

          {/* this is for create button  */}

          {createButtonValue && (
            <Box
            // style={{
            //   cursor:
            //     permissions?.permissions?.writeContent ||
            //     permissions?.permissions?.modifyContent ||
            //     permissions?.permissions?.publishContent ||
            //     permissions?.permissions?.admin
            //       ? "pointer"
            //       : "not-allowed",
            //   opacity:
            //     permissions?.permissions?.writeContent ||
            //     permissions?.permissions?.modifyContent ||
            //     permissions?.permissions?.publishContent ||
            //     permissions?.permissions?.admin
            //       ? 1
            //       : 0.5,
            // }}
            >
              <Button
                startIcon={createIcon}
                onClick={createOnClick}
                // disabled={
                //   !permissions?.permissions?.writeContent &&
                //   !permissions?.permissions?.modifyContent &&
                //   !permissions?.permissions?.publishContent &&
                //   !permissions?.permissions?.admin
                // }
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #DDE1E6",
                  background: createBgColor,
                  // `${
                  //   !permissions?.permissions?.writeContent &&
                  //   !permissions?.permissions?.modifyContent &&
                  //   !permissions?.permissions?.publishContent &&
                  //   !permissions?.permissions?.admin
                  //     ? "#F7f7f7"
                  //     : createBgColor
                  // }`,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  marginRight: "1rem",
                  // color: `${
                  //   !permissions?.permissions?.writeContent &&
                  //   !permissions?.permissions?.modifyContent &&
                  //   !permissions?.permissions?.publishContent &&
                  //   !permissions?.permissions?.admin
                  //     ? "#e9e9e9"
                  //     : "#fff"
                  // }`,
                  "&:hover": {
                    background: "#ee9329d1", // Set your desired hover color here
                    color: "#f7f7f7", // Set your desired hover text color here
                  },
                }}
              >
                {createButtonValue}
              </Button>
            </Box>
          )}
          {/* //export button for common chart */}
          {exportModuleName && (
            <Box
            // style={{
            //   cursor:
            //     permissions?.permissions?.publishContent ||
            //     permissions?.permissions?.admin
            //       ? "pointer"
            //       : "not-allowed",
            //   opacity:
            //     permissions?.permissions?.publishContent ||
            //     permissions?.permissions?.admin
            //       ? 1
            //       : 0.5,
            // }}
            >
              <Button
                startIcon={<img src={ExportIcon} alt={"Export Button"} />}
                onClick={onBtnExport}
                // disabled={
                //   !permissions?.permissions?.publishContent &&
                //   !permissions?.permissions?.admin
                // }
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #DDE1E6",
                  // background: `${
                  //   !permissions?.permissions?.publishContent &&
                  //   !permissions?.permissions?.admin
                  //     ? "#F7f7f7"
                  //     : "#fff"
                  // }`,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  marginRight: "1rem",
                  // color: `${
                  //   !permissions?.permissions?.publishContent &&
                  //   !permissions?.permissions?.admin
                  //     ? "gray"
                  //     : "#EE9329"
                  // }`,
                }}
              >
                {"Export"}
              </Button>
            </Box>
          )}

          {isFilterColumn && (
            <div>
              <Button
                id="fade-button"
                aria-controls={open ? "fade-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #DDE1E6",
                  background: "#FFF",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  marginRight: "1rem",
                  color: "#EE9329",
                  padding: "6px",
                  minWidth: "30px",
                }}
                className="refreshButton"
              >
                {/* <FilterAlt /> */}
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                {/* <MultiCheckboxFilter
                  filterOptions={columnDefs}
                  selectedFilterOptions={tableColumnData.map(
                    (res) => res?.field
                  )}
                  onFilterChange={handleFilterChange}
                /> */}
              </Menu>
            </div>
          )}

          {onRefresh && (
            <Tooltip title={"Refresh data based on current filter"}>
              <Button
                onClick={onRefresh}
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #DDE1E6",
                  background: "#FFF",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  marginRight: "1rem",
                  color: "#EE9329",
                  padding: "6px",
                  minWidth: "30px",
                }}
                className="refreshButton"
              >
                <CachedOutlined />
              </Button>
            </Tooltip>
          )}
          {backButtonValue && (
            <Box>
              <Button
                startIcon={icon1}
                onClick={onClick}
                style={buttonStyles}
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #DDE1E6",
                  background: "#FFF",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  marginRight: "1rem",
                  color: "#EE9329",
                }}
              >
                {backButtonValue}
              </Button>
            </Box>
          )}
          {buttonValue1 && (
            <Box>
              <Button
                startIcon={icon1}
                onClick={addAction1}
                style={buttonStyles}
                sx={{
                  borderRadius: "20px",
                  border: "1px solid #DDE1E6",
                  background: "#FFF",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontFamily: "Inter",
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  marginRight: "1rem",
                  color: "#EE9329",
                }}
              >
                {buttonValue1}
              </Button>
            </Box>
          )}
          <IconButton
            sx={{
              borderRadius: "20px",
              border: "1px solid #DDE1E6",
              background: "#FFF",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontFamily: "Inter",
              fontSize: "13px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              marginRight: "1rem",
              color: "#EE9329",
              padding: "6px",
            }}
            cellRenderer
            onClick={onToggleFullscreen}
          >
            {isFullscreen ? <CloseFullscreenIcon /> : <OpenInNewIcon />}
          </IconButton>
        </Stack>
        <Divider />
        {isGridRequired && (
          <Box p={2} style={{ height: `${isFullscreen ? "100vh" : ""}` }}>
            {isLoading ? (
              // <CircularLoadingProgress />
              <Typography>...Loding</Typography>
            ) : !rowData?.length ? (
              <Box
                sx={{
                  height: "520px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    padding: "10px",
                    border: "2px solid #444",
                    backgroundColor: "lightgoldenrodyellow",
                  }}
                >
                  No data was found
                </span>
              </Box>
            ) : (
              <div
                className="ag-theme-alpine"
                style={{
                  height: isFullscreen ? "90vh" : `calc(100vh - 300px)`,
                  width: "100%",
                  minHeight: "500px",
                  paddingTop: isFullscreen ? "10px" : "0",
                  paddingBottom: isFullscreen ? "50px" : "0",
                  boxSizing: "border-box",
                }}
              >
                <AgGridReact
                  rowData={tableRowData || []}
                  columnDefs={tableColumnData || []}
                  ref={gridRef}
                  defaultColDef={defaultColDef}
                  pagination={isServerPagination ? false : true}
                  paginationPageSize={20}
                  animateRows={true}
                  enableSorting={true}
                  enableFilter={true}
                  suppressDragLeaveHidesColumns={true}
                  enableRangeSelection={true} // Enable range selection
                  enableRangeHandle={true}
                  pinnedBottomRowData={pinnedBottomRowData}
                  getRowStyle={getRowStyle}
                  enableCellTextSelection="true"
                  suppressExcelExport={true}
                  suppressHorizontalScroll={false}
                  readOnlyEdit={true}
                  rowMode
                />
              </div>
            )}
          </Box>
        )}

        <Box>{children}</Box>
      </Box>
    </Box>
  );
}

export default CommonChart;
