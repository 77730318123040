import { Box, Button, Grid } from "../../collections/Imports";
import "../commonFilter/style.css";
function Filters(props) {
  const { id, children, title, onClick } = props;

  return (
    <Box>
      <Box
        className="filter-box"
        component={Button}
        sx={{ color: "unset", fontWeight: "400" }}
        onClick={onClick}
      >
        <div className="flexee">
          <div className="title">{title}</div>
          <div className="children">{children}</div>
        </div>
      </Box>
    </Box>
  );
}

export default Filters;
