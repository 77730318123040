import React, { createContext, useContext, useState } from "react";
const TabContext = createContext();

const TabProvider = ({ children }) => {
  const [tabValue, setTabValue] = useState(1);
  const handleChange = (newValue) => {
    debugger;
    setTabValue(newValue);
  };

  const [secondTabValue, setSecondTabValue] = useState(1);
  const handleSecondTabChange = (newValue) => {
    setSecondTabValue(newValue);
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleDateRange = (newValue) => {
    setDateRange(newValue);
  };

  const [selectedFilter, setSelectedFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleSelectedFilter = (newValue) => {
    debugger;
    setSelectedFilter(newValue);
  };
  return (
    <TabContext.Provider
      value={{
        tabValue,
        handleChange,
        secondTabValue,
        handleSecondTabChange,
        dateRange,
        handleDateRange,
        selectedFilter,
        handleSelectedFilter,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

// Custom hook to access tabValue and handleChange
const useTabContext = () => {
  const context = useContext(TabContext);
  if (!context) {
    throw new Error("useTabContext must be used within a TabProvider");
  }
  return context;
};

export { TabProvider, useTabContext };
