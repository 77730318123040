import { Box } from "@mui/material";
import React from "react";

const DsaDashboard = () => {
  return (
    <>
      <Box>Hello World</Box>
    </>
  );
};

export default DsaDashboard;
