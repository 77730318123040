import { createTheme, ThemeProvider } from "@mui/material/styles";
import api from "../../helpers/apiService";
import apiEndPointsConfig from "helpers/apiEndPointsConfig";
import LoginLogo from "../../assets/images/login-logo.png";
import LoginBackground from "../../assets/images/login-bg.png";
import {
  Box,
  Typography,
  FormGroup,
  FormLabel,
  TextField,
  Button,
  LoadingButton,
  useSnackbar,
  useNavigate,
  Yup,
  useFormik,
  useState,
} from "../../collections/Imports";
import { useApiCallMutation } from "helpers/apiQueryServices";
import { useEffect } from "react";
const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const defaultTheme = createTheme();
const Login = () => {
  const user = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [apiCall, res] = useApiCallMutation();

  useEffect(() => {
    if (user) {
      navigate(-1);
    }
  }, []);
  useEffect(() => {
    if (res.isSuccess) {
      if (!res?.data?.error) {
        localStorage.setItem("accessToken", res.data.data.token.accessToken);
        localStorage.setItem("username", res.data.data.token.username);
        localStorage.setItem("refressToken", res.data.data.token.refreshToken);
        enqueueSnackbar("Login Successfully", { variant: "success" });
        navigate("/");
      } else {
        enqueueSnackbar("Faild to Login", { variant: "error" });
      }
    }

    if (res.isError) {
      enqueueSnackbar("Faild to Login", { variant: "error" });
    }
  }, [res]);

  const onSubmit = (values) => {
    const data = {
      emailId: values.email,
      password: values.password,
    };

    apiCall({
      endPoint: apiEndPointsConfig.login,
      method: "POST",
      data: { data },
    });
    // api.post(apiEndPointsConfig.login, { data })
    //     .then((res) => {
    //         console.log('DATA Login', res.data.data);
    //         localStorage.setItem('accessToken', res.data.data.token.accessToken);
    //         localStorage.setItem('username', res.data.data.token.username);
    //         localStorage.setItem('refressToken', res.data.data.token.refreshToken);
    //         setLoading(false);
    //         enqueueSnackbar('Login Successfully', { variant: 'success' });
    //         navigate('/dashboard');
    //     })
    //     .catch((error) => {
    //         console.log(error);
    //         setLoading(false);
    //         enqueueSnackbar('Faild to Login', { variant: 'error' });
    //     });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            width: "100%",
            height: "100%",
          }}
        >
          <Box sx={{ width: "20px", backgroundColor: "#0e356cd9" }}></Box>
          <Box sx={{ width: "100%" }} bgcolor="#0E356C" position="relative">
            <Box position="absolute" height="100%" right={1} bottom={1}>
              <img
                src={LoginBackground}
                alt="Login Background"
                width="100%"
                height="100%"
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              mx={4}
              gap={2}
              sx={{ position: "absolute", top: "2rem" }}
            >
              <img src={LoginLogo} alt="Login Logo" />
              <Typography
                variant="p"
                color="white"
                fontWeight="700"
                fontSize="20px"
              >
                Customer Relationship Management
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={"100%"}
            >
              <Box
                width="550px"
                bgcolor="white"
                py={6}
                px={4}
                m={"10px"}
                borderRadius={3}
              >
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                  onSubmit={formik.handleSubmit}
                >
                  <Typography color="#0E356C" fontWeight="600" fontSize="24px">
                    Log in
                  </Typography>
                  <FormGroup>
                    <FormLabel htmlFor="email">Username</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      id="email"
                      name="email"
                      // label="Email"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && formik.errors.email
                          ? true
                          : false
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      id="password"
                      name="password"
                      // label="Password"
                      type="password"
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password && formik.errors.password
                          ? true
                          : false
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </FormGroup>
                  <Box display="flex" gap={2}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: "#EE9329",
                        textTransform: "capitalize",
                      }}
                      loading={res.isLoading}
                      // loadingPosition="start"
                      disableElevation
                    >
                      {!res.isLoading ? "Log in" : ""}
                    </LoadingButton>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "capitalize",
                        border: "1px solid gray",
                        color: "gray",
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};
export default Login;
