import React from 'react';
import { DateRangePicker, createStaticRanges } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Stack } from '../../collections/Imports';

const CommonDateRangePicker = ({ staticRanges, maxDate, ranges, onChange }) => {
    return (
        <Stack marginTop={2} spacing={2}>
            <DateRangePicker staticRanges={createStaticRanges(staticRanges)} maxDate={maxDate} ranges={ranges} onChange={onChange} />
        </Stack>
    );
};

export default CommonDateRangePicker;
