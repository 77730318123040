import React, {
  useMemo,
  useRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Box,
  Card,
  Switch,
  TablePagination,
  TextField,
  Button,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import Icon from "@mdi/react";
import {
  mdiPencil,
  mdiDelete,
  mdiShieldKeyOutline,
  mdiCog,
  mdiSetCenter,
  mdiExport,
} from "@mdi/js";
// import CommonFunctions from "../../../Utils/CommonFunctions";
import "./agGrid.css";
import useDownloadCSV from "CustomHooks/DownloadCSV";
import { width } from "@mui/system";

let userPermissions = JSON.parse(localStorage.getItem("userInfo"));

const AgGridComponent = ({
  setselectedRows,
  rowData,
  columnDefs,
  pagination,
  onRowSelectionChangedHandler,
  height,
  search,
  floatingFilter,
  actions,
  actioncondition,
  performAction,
  onPageChange,
  onRowsPerPageChange,
  totalPageCount,
  itemPerIndex,
  paginationIndex,
  perPageCountOptions,
  // page,
  // rowsPerPage,
  // setPage,
  // setRowsPerPage,
  ...rest
}) => {
  const [column, setColumn] = useState();
  const [row, setRow] = useState();
  const [isGridDisplay, setIsGridDisplay] = useState(false);
  const gridRef = useRef();
  const downloadTableData = useDownloadCSV();

  // Rows per page

  const handleChangePage = (event, newPage) => {
    if (onPageChange !== undefined && onPageChange !== null) {
      onPageChange(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    if (onRowsPerPageChange !== undefined && onRowsPerPageChange !== null) {
      onRowsPerPageChange(event.target.value);
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      // width: 200,
      flex: 1,
      minWidth: 130,
      enableValue: true,
      sortable: true,
      resizable: true,
      filter: true,
      headerClass: "centered-header",
      enableCellSelection: true,
      autoHeaderHeight: true,
      // editable: true,
      // enableRowGroup: true,
      // enablePivot: true,
      // floatingFilter: true,
      // minWidth: 200,
    };
  }, []);

  useEffect(() => {
    userPermissions = JSON.parse(localStorage.getItem("userInfo"));
  }, []);

  const executeActions = (data, action) => {
    if (typeof performAction !== "undefined") performAction(data, action);
  };

  const onRowSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (
      onRowSelectionChangedHandler !== undefined &&
      onRowSelectionChangedHandler !== null
    ) {
      onRowSelectionChangedHandler(selectedRows);
    }

    if (setselectedRows !== undefined) {
      setselectedRows(selectedRows.length);
    }
  }, []);
  const onBtShowNoRows = useCallback(() => {
    gridRef.current.api.showNoRowsOverlay();
  }, []);

  const onBtHide = useCallback(() => {
    gridRef.current.api.hideOverlay();
  }, []);

  const statusSwitch = useCallback((params) => {
    return (
      <span>
        <div>
          <Switch
            title={params.data?.buyerStatus ? "Active" : "Inactive"}
            inputProps={{ "aria-label": "controlled" }}
            size="small"
            labelPlacement="bottom"
            color={params.data?.buyerStatus ? "info" : "warning"}
            checked={params.data?.buyerStatus}
            onChange={() => {
              if (
                userPermissions?.permissions?.includes("Modify Content") ||
                userPermissions?.roles[0] === "admin"
              ) {
                executeActions(params.data, "Status");
              }
            }}
            sx={{
              display: params.data?.isDeleted ? "none" : "",
              marginBottom: "20px",
              cursor:
                userPermissions?.permissions?.includes("Modify Content") ||
                userPermissions?.roles[0] === "admin"
                  ? "pointer"
                  : "not-allowed",
            }}
            disabled={
              !(
                userPermissions?.permissions?.includes("Modify Content") ||
                userPermissions?.roles[0] === "admin"
              )
            }
          />
        </div>
      </span>
    );
  }, []);

  const actionButtons = useCallback((params) => {
    return (
      <span>
        <div style={{ textAlign: "center" }}>
          {typeof actions !== "undefined" && actions.includes("EDIT") && (
            <Icon
              path={mdiPencil}
              title="Edit"
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "EDIT");
                }
              }}
              size={1}
              style={{
                color: "#233784",
                display:
                  typeof actioncondition !== "undefined"
                    ? params.data[actioncondition?.edit?.key] ===
                      actioncondition?.edit?.value
                      ? "none"
                      : "inline-block"
                    : params.data?.isDeleted
                    ? "none"
                    : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("REGENERATE") && (
            <Icon
              path={mdiShieldKeyOutline}
              title="Re-Generate Key"
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "REGENERATE");
                }
              }}
              size={1}
              style={{
                color: "#ffc412",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("CONFIG") && (
            <Icon
              path={mdiCog}
              title={params.data?.mappedHeader ? "Update Config" : "Set Config"}
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "CONFIG");
                }
              }}
              size={1}
              style={{
                color: params.data?.config ? "blue" : "grey",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("MAPHEADER") && (
            <Icon
              path={mdiSetCenter}
              title={
                params.data?.mappedHeader ? "Update Map Headers" : "Map Headers"
              }
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "MAPHEADER");
                }
              }}
              size={1}
              style={{
                color: params.data?.mappedHeader ? "blue" : "grey",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Publish Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
          {typeof actions !== "undefined" && actions.includes("ACTIVE") && (
            <Switch
              title={params.data?.isActive ? "Active" : "Inactive"}
              inputProps={{ "aria-label": "controlled" }}
              size="small"
              labelPlacement="bottom"
              color={params.data?.isActive ? "success" : "warning"}
              checked={params.data?.isActive}
              onChange={() => {
                if (
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "ACTIVE");
                }
              }}
              sx={{
                display: params.data?.isDeleted ? "none" : "",
                marginBottom: "20px",
                cursor:
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
              disabled={
                !(
                  userPermissions?.permissions?.includes("Modify Content") ||
                  userPermissions?.roles[0] === "admin"
                )
              }
            />
          )}

          {typeof actions !== "undefined" && actions.includes("DELETE") && (
            <Icon
              path={mdiDelete}
              title="Delete"
              onClick={() => {
                if (
                  userPermissions?.permissions?.includes("Delete Content") ||
                  userPermissions?.roles[0] === "admin"
                ) {
                  executeActions(params.data, "DELETE");
                }
              }}
              size={1}
              style={{
                color: "#b71c1c",
                display: params.data?.isDeleted ? "none" : "inline-block",
                opacity:
                  userPermissions?.permissions?.includes("Delete Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? 1
                    : 0.5,
                cursor:
                  userPermissions?.permissions?.includes("Delete Content") ||
                  userPermissions?.roles[0] === "admin"
                    ? "pointer"
                    : "not-allowed",
              }}
            />
          )}
        </div>
      </span>
    );
  }, []);

  const centerAlignedHeaderComponent = (props) => {
    return <div style={{ textAlign: "center" }}>Action</div>;
  };

  useEffect(() => {
    if (columnDefs.length && columnDefs !== undefined) {
      let col = [...columnDefs];
      if (typeof actions !== "undefined" && actions.length > 0) {
        col.push({
          field: "Action",
          cellRenderer: actionButtons,
          headerComponent: centerAlignedHeaderComponent,
          width: "200px",
        });
      }
      const indexOfStatus = col.findIndex(
        (item) => item.headerName === "Status"
      );
      if (indexOfStatus) {
        col[indexOfStatus] = {
          field: "Status",
          cellRenderer: statusSwitch,
          headerComponent: centerAlignedHeaderComponent,
        };
      }
      setColumn(col);
      // Slice the data based on the current page and rowsPerPage
      // const startIndex = page * rowsPerPage;
      // const endIndex = startIndex + rowsPerPage;
      // const slicedData = rowData.slice(startIndex, endIndex);

      setRow(rowData);
    } else {
      setIsGridDisplay(true);
    }
  }, [columnDefs]);
  useEffect(() => {
    // Slice the data based on the current page and rowsPerPage
    // const startIndex = page * rowsPerPage;
    // const endIndex = startIndex + rowsPerPage;
    // const slicedData = rowData.slice(startIndex, endIndex);

    setRow(rowData);
  }, [rowData]);

  const handleInputChange = (event) => {
    gridRef.current.api.setQuickFilter(event.target.value);

    if (gridRef.current.api.filterManager.rowModel.rowsToDisplay.length <= 0) {
      onBtShowNoRows();
    } else {
      onBtHide();
    }
  };
  const csvDownload = () => {
    const Keys = column
      .map((res) => res.field)
      .filter((res) => res !== "Action");
    if (gridRef.current.api.filterManager.rowModel.rowsToDisplay.length) {
      const searchedData =
        gridRef.current.api.filterManager.rowModel.rowsToDisplay.map(
          (res) => res.data
        );
      downloadTableData(searchedData, Keys, "example_report");
    } else {
      downloadTableData(row, Keys, "example_report");
    }
    setTimeout(() => {}, 1000);
  };

  return (
    <div
      className="ag-theme-alpine"
      style={{
        width: "100%",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
        paddingLeft: "10px",
        paddingRight: "10px",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.12)",
      }}
    >
      <Box
        sx={
          {
            // background: "#ffffff",
            // height: height || "60vh",
          }
        }
      >
        {search !== false && (
          <Box display="flex" justifyContent="flex-end">
            <Box
              sx={{
                padding: "10px 0px",
              }}
            >
              <TextField
                id="search_Inp"
                // label="Search"
                placeholder="Search..."
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    fontSize: "12px",
                    padding: "8px",
                    height: "2rem",

                    mb: "10px",
                    borderRadius: "20px",
                  },
                  "& .MuiInputLabel-root": {
                    fontSize: "12px",
                  },
                }}
                onInput={handleInputChange}
              />
            </Box>
            <Box
              sx={{
                padding: "10px 0px",
                marginLeft: "2%",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: 20,
                  height: "2rem",
                }}
                onClick={csvDownload}
              >
                <Icon
                  path={mdiExport}
                  size={1}
                  color="#FFFFFF"
                  style={{ marginRight: 8 }}
                />
                Export
              </Button>
              {/* <Button
                variant="contained"
                // startIcon={<Icon path={mdiDownloadBoxOutline} size={1} />}
                onClick={csvDownload}
              >
                Download
              </Button> */}
            </Box>
          </Box>
        )}

        {(row || isGridDisplay) && (
          <>
            <Box
              sx={{
                background: "#ffffff",
                height: height || "75vh",
              }}
            >
              <AgGridReact
                rowData={row || []}
                columnDefs={column || []}
                defaultColDef={defaultColDef}
                pagination={pagination ? pagination : false}
                ref={gridRef}
                animateRows={true}
                paginationPageSize={100}
                enableSorting={true}
                enableFilter={true}
                suppressDragLeaveHidesColumns={true}
                rowSelection={"multiple"}
                // suppressSizeToFit={true}
                onSelectionChanged={onRowSelectionChanged}
                // onGridReady={onGridReady}
                enableRangeSelection={true} // Enable range selection
                enableRangeHandle={true}
                overlayNoRowsTemplate={
                  '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">No data was found</span>'
                }
                enableCellTextSelection="true"
                {...rest}
                // copyHeadersToClipboard={true}
                // suppressCopyRowsToClipboard={true}
                // getRowId={getRowId}
                // paginationGoToPage={handlePaginationGoToPage}
                // paginationGetRowCount={paginator?.itemCount}
                // paginationGetRowCount={paginator.itemCount}
                // paginationGetTotalPages={paginator?.pageCount}
                // onSelectionChanged={onRowSelectionChanged}
                suppressHorizontalScroll={false}
                // headerHeight={150}
              ></AgGridReact>
            </Box>
            {perPageCountOptions !== undefined &&
              perPageCountOptions !== null && (
                <Box>
                  <Card>
                    <TablePagination
                      component="div"
                      count={totalPageCount || 0}
                      page={paginationIndex || 0}
                      onPageChange={handleChangePage}
                      rowsPerPage={itemPerIndex || 0}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      rowsPerPageOptions={perPageCountOptions}
                    />
                  </Card>
                </Box>
              )}
          </>
        )}
      </Box>
    </div>
  );
};

export default AgGridComponent;
