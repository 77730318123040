import { mdiFile, mdiCogBox } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import AgGridComponent from "ui-component/aggridComponent";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  TextField,
  Box,
  Typography,
  Chip,
  Grid,
  Stack,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useApiCallMutation } from "helpers/apiQueryServices";
import apiEndPointsConfig from "helpers/apiEndPointsConfig";
import BoxComponent from "ui-component/CircularProgressLoading";
import { useSnackbar } from "notistack";
import {
  AddOutlined,
  Drawer,
  LoadingButton,
  Divider,
} from "../../collections/Imports";
import Filters from "ui-component/commonFilter/Filters";
import moment from "moment/moment";
import CommonAccordion from "ui-component/commonAccordion";
import CommonDateRangePicker from "ui-component/commonDateRangePicker";
import { useFormik } from "formik";
import { maxWidth, width } from "@mui/system";
import CommonChart from "ui-component/commonChart/CommonChart";
import { useTabContext } from "context/TabContext";

const SalariedLeads = ({ employmnetStatus, progressStatus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isRemarkRequired, setIsRemarkRequired] = useState(false);
  const [isCallDespositionRequired, setIsCallDespositionRequired] =
    useState(false);
  const [tableData, setTableData] = useState();
  const [callDesposition, setCallDesposition] = useState(null);
  const [remark, setRemark] = useState(null);
  const [rowData, setRowData] = useState({});

  const [getSalariedLeads, getSalariedLeadsData] = useApiCallMutation();
  const [getCallDespositiuon, CallDespositiuonData] = useApiCallMutation();
  const [update, UpdateData] = useApiCallMutation();

  const [defaultDate, setDefaultDate] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const fromDate = moment().startOf("day").toISOString();
  // const [selectedFilter, setSelectedFilter] = useState({
  //   startDate: new Date(),
  //   endDate: new Date(),
  //   key: "selection",
  // });

  const { dateRange, handleDateRange, selectedFilter, handleSelectedFilter } =
    useTabContext();
  // const [dateRange, setDateRange] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: "selection",
  //   },
  // ]);

  const formik = useFormik({
    initialValues: {
      dateFrom: new Date().setMonth(new Date().getMonth() - 1),
      dateTo: moment().endOf("day").toISOString(),
    },
    onSubmit: (values) => {
      // setLoadingGif(true);
      debugger;
      handleSelectedFilter(dateRange[0]);

      const data = {
        fromDate: moment(dateRange[0].startDate).startOf("day").toISOString(),
        toDate: moment(dateRange[0]?.endDate).endOf("day").toISOString(),
        index: 0,
        itemPerIndex: 1000,
        empType: employmnetStatus,
        progressStatus: progressStatus,
      };
      getSalariedLeads({
        endPoint: apiEndPointsConfig?.getSelfEmpLeads,
        method: "POST",
        data: { data },
      });
      setIsOpen(false);
      // enqueueSnackbar("Updated Successfully", { variant: "success" });
    },
  });

  const toggleDrawer = (ele) => {
    ele === "date" ? setDefaultDate(true) : setDefaultDate(false);
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    const data = {
      index: 0,
      itemPerIndex: 1000,
      empType: employmnetStatus,
      progressStatus: progressStatus,
      fromDate: fromDate,
      toDate: moment().endOf("day").toISOString(),
    };
    getSalariedLeads({
      endPoint: apiEndPointsConfig?.getSelfEmpLeads,
      method: "POST",
      data: { data },
    });
    getCallDespositiuon({
      endPoint: apiEndPointsConfig?.getCallDespostionList,
      method: "GET",
    });
  }, []);

  useEffect(() => {
    debugger;
    const data = {
      fromDate: moment(dateRange[0].startDate).startOf("day").toISOString(),
      toDate: moment(dateRange[0]?.endDate).endOf("day").toISOString(),
      index: 0,
      itemPerIndex: 1000,
      empType: employmnetStatus,
      progressStatus: progressStatus,
    };
    getSalariedLeads({
      endPoint: apiEndPointsConfig?.getSelfEmpLeads,
      method: "POST",
      data: { data },
    });
  }, [employmnetStatus]);
  const actionButtonsPdf = (params) => {
    return (
      <span>
        <div style={{ textAlign: "center", marginTop: "0.3rem" }}>
          <Icon
            path={mdiCogBox}
            title="Update Status"
            onClick={(r) => {
              handleClickOpen(params);
            }}
            size={1}
            style={{
              color: "#1976d2",
              cursor: "pointer",
            }}
          />
        </div>
      </span>
    );
  };

  const centerAlignedHeaderComponent = (props) => {
    return <div style={{ textAlign: "center", width: "100%" }}>Action</div>;
  };

  useEffect(() => {
    if (getSalariedLeadsData?.isSuccess) {
      if (
        getSalariedLeadsData?.data?.data?.leads?.length &&
        getSalariedLeadsData?.data?.data?.headers?.length
      ) {
        const modifiedHeaders = [...getSalariedLeadsData?.data?.data?.headers];
        if (progressStatus === "new") {
          modifiedHeaders.splice(
            modifiedHeaders.findIndex(
              (obj) => obj?.field === "callDisposition"
            ),
            1
          );
          modifiedHeaders.splice(
            modifiedHeaders.findIndex((obj) => obj?.field === "remark"),
            1
          );
          console.log(modifiedHeaders);
        } else {
          modifiedHeaders.splice(
            modifiedHeaders.findIndex(
              (obj) => obj?.field === "callDisposition"
            ),
            1,
            {
              field: "callDisposition",
              headerName: "Call Disposition",
              pinned: "right",
              cellClass: "my-class",
              textAlign: "center",
              width: 100,
              maxWidth: 150,
              defaultWidth: 100,
            }
          );
          modifiedHeaders.splice(
            modifiedHeaders.findIndex((obj) => obj?.field === "remark"),
            1,
            {
              field: "remark",
              headerName: "Remark",
              pinned: "right",
              cellClass: "my-class",
              textAlign: "center",
              width: 100,
              maxWidth: 180,
              defaultWidth: 100,
            }
          );
        }
        modifiedHeaders.push({
          field: "Action",
          cellRenderer: actionButtonsPdf,
          pinned: "right",
          cellClass: "my-class",
          textAlign: "center",
          headerComponent: centerAlignedHeaderComponent,
          width: 100,
          maxWidth: 100,
          defaultWidth: 100,
        });
        const tabledata = {
          data: getSalariedLeadsData?.data?.data?.leads,
          headers: modifiedHeaders,
        };
        setTableData(tabledata);
      }
      if (getSalariedLeadsData?.data?.error) {
        enqueueSnackbar(getSalariedLeadsData?.data?.data, { variant: "error" });
      }
    }
  }, [getSalariedLeadsData]);

  useEffect(() => {
    if (CallDespositiuonData?.isSuccess) {
      if (CallDespositiuonData?.data?.error) {
        enqueueSnackbar(CallDespositiuonData?.data?.data, { variant: "error" });
      }
    }
  }, [CallDespositiuonData]);

  const handleClickOpen = (params) => {
    setRowData(params?.data);
    setOpen(true);
  };

  const handleUpdate = () => {
    if (remark && callDesposition && Object.keys(rowData).length) {
      const data = {
        id: rowData?.userId,
        leadId: rowData?.leadId,
        callDispositionId: callDesposition?.id,
        remark: remark,
      };
      update({
        endPoint: apiEndPointsConfig?.updateCallDesposition,
        method: "POST",
        data: { data },
      });
    } else {
      if (!remark) {
        setIsRemarkRequired(true);
      } else {
        setIsRemarkRequired(false);
      }

      if (!callDesposition?.id) {
        setIsCallDespositionRequired(true);
      } else {
        setIsCallDespositionRequired(false);
      }
    }
  };

  const clearAllState = () => {
    setIsRemarkRequired(false);
    setIsCallDespositionRequired(false);
    setCallDesposition(null);
    setRemark(null);
    setRowData({});
  };

  useEffect(() => {
    if (UpdateData?.isSuccess) {
      if (UpdateData?.data?.error) {
        enqueueSnackbar(UpdateData?.data?.data, { variant: "error" });
      } else {
        setOpen(false);
        clearAllState();
        const data = {
          fromDate: moment(dateRange[0].startDate).startOf("day").toISOString(),
          toDate: moment(dateRange[0]?.endDate).endOf("day").toISOString(),
          index: 0,
          itemPerIndex: 1000,
          empType: employmnetStatus,
          progressStatus: progressStatus,
        };
        getSalariedLeads({
          endPoint: apiEndPointsConfig?.getSelfEmpLeads,
          method: "POST",
          data: { data },
        });

        enqueueSnackbar("Status Updated Successfully", { variant: "success" });
      }
    }
  }, [UpdateData]);

  const handleClose = () => {
    setOpen(false);
  };

  if (getSalariedLeadsData?.isLoading) {
    return <BoxComponent />;
  }

  const handleClearFilter = () => {
    formik.resetForm();
    handleDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    handleSelectedFilter({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });

    const data = {
      fromDate: fromDate,
      toDate: moment().endOf("day").toISOString(),
      index: 0,
      itemPerIndex: 1000,
      empType: employmnetStatus,
      progressStatus: progressStatus,
    };
    getSalariedLeads({
      endPoint: apiEndPointsConfig?.getSelfEmpLeads,
      method: "POST",
      data: { data },
    });
    // getCallDespositiuon({
    //   endPoint: apiEndPointsConfig?.getCallDespostionList,
    //   method: "GET",
    // });
  };

  const staticRanges = [
    {
      label: "Today",
      range: () => ({
        startDate: new Date(),
        endDate: new Date(),
      }),
    },
    {
      label: "Yesterday",
      range: () => {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        const startDate = new Date(endDate);
        startDate.setHours(0, 0, 0, 0);
        return { startDate, endDate };
      },
    },
    {
      label: "Last 7 Days",
      range: () => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 6);
        return { startDate, endDate };
      },
    },
    {
      label: "Last 30 Days",
      range: () => {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(endDate.getDate() - 29);
        return { startDate, endDate };
      },
    },
    {
      label: "This Month",
      range: () => {
        const endDate = new Date();
        const startDate = new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          1
        );
        return { startDate, endDate };
      },
    },
  ];
  const handleDateRangeChange = (item) => {
    if (item && item.selection) {
      handleDateRange([item.selection]);
    }
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer}
        className="drawar-style"
        sx={{
          "& .MuiDrawer-paperAnchorRight": {
            margin: 2,
            height: "98vh",
            borderRadius: "8px",
            border: "1px solid #DFE3E7",
            background: "#FFF",
            boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        <Box minWidth="500px" maxWidth="500px">
          <Box px={1} component={"form"} onSubmit={formik?.handleSubmit}>
            <Box
              className="filter-header"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography className="filter-title">Filters</Typography>
              <Button
                onClick={handleClearFilter}
                variant="text"
                className="clear-filter"
              >
                Clear all
              </Button>
            </Box>
            <Divider />
            <Box mt={2}>
              <CommonAccordion
                defaultExpanded={true}
                title={"Select Date Range"}
              >
                <CommonDateRangePicker
                  staticRanges={staticRanges}
                  maxDate={new Date()}
                  ranges={dateRange}
                  onChange={handleDateRangeChange}
                />
              </CommonAccordion>
              <Box
                sx={{
                  borderTop: "1px solid #DDE1E6",
                  backgroundColor: "#fff",
                }}
              >
                <Grid container justifyContent="flex-end" align-items="center">
                  <Grid item sm={3} mt={2}>
                    <LoadingButton
                      loading={loading}
                      // loadingPosition="end"
                      type="submit"
                      variant="contained"
                      className="apply-button"
                    >
                      Apply Filter
                    </LoadingButton>
                  </Grid>
                  <Grid item sm={2} mt={2}>
                    <Button
                      onClick={toggleDrawer}
                      variant="outlined"
                      className="cancel-button"
                    >
                      Close
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Box sx={{ mb: "6px", display: "flex" }}>
        {/* <Stack direction="row"> */}
        <Filters title="Date :" onClick={() => toggleDrawer("date")}>
          <Box>
            <Chip
              label={moment(selectedFilter?.startDate).format("ll")}
              sx={{ m: "2px" }}
              size="small"
            />
            {"-"}{" "}
            <Chip
              label={moment(selectedFilter?.endDate).format("ll")}
              sx={{ m: "2px" }}
              size="small"
            />
          </Box>
        </Filters>

        <Filters onClick={toggleDrawer}>
          <Button
            sx={{ color: "gray", margin: "auto 0", padding: 0 }}
            startIcon={<AddOutlined />}
          >
            Filter
          </Button>
        </Filters>
        <Filters>
          <Button
            onClick={handleClearFilter}
            variant="text"
            sx={{
              textDecoration: "underline",
              color: "gray",
              margin: "auto 0",
              width: "100%",
              p: 0,
            }}
          >
            Clear All
          </Button>
        </Filters>
        {/* </Stack> */}
      </Box>
      {/* <AgGridComponent
        rowData={tableData?.data || []}
        columnDefs={tableData?.headers || []}
        onRowSelectionChangedHandler={(arg) => console.log(arg)}
        pagination={true}
        paginationPageSize={100}
        // domLayout="autoHeight"
        rowMode
      /> */}
      <CommonChart
        title={""}
        // exportModuleName={'Non Eligible Leads'}
        // onToggleFullscreen={handleToggleFullscreen}
        // isFullscreen={isFullscreen}
        addActionIcon
        // onRefresh={onRefresh}
        rowData={tableData?.data || []}
        columnDefs={tableData?.headers || []}
        isGridRequired={true}
        isServerPagination={false}
        // isLoading={tableDataLoading}
      >
        {/* <TablePagination
          component="div"
          onPageChange={handlePageChange}
          page={controller.page}
          count={totalData}
          rowsPerPage={controller.rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[1000, 2500, 5000, 10000]}
          style={{
            ...(isFullscreen && {
              position: "absolute",
              bottom: "20px",
              right: 0,
              left: 0,
            }),
          }}
        /> */}
      </CommonChart>
      <Dialog fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Update Call Desposition</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              padding: "10px",
            }}
          >
            <DialogContentText
              sx={{
                marginBottom: "5px",
              }}
            >
              Call Desposition *
            </DialogContentText>
            <Autocomplete
              fullWidth
              disablePortal
              id="combo-box-demo"
              options={CallDespositiuonData.data?.data || []}
              getOptionLabel={(options) => options?.name || null}
              size="small"
              onChange={(e, newvalue) => {
                setCallDesposition(newvalue);
                if (newvalue?.id) {
                  setIsCallDespositionRequired(false);
                }
              }}
              onBlur={(event) => {
                if (!event.target.value) {
                  setIsCallDespositionRequired(true);
                } else {
                  setIsCallDespositionRequired(false);
                }
              }}
              renderInput={(params) => (
                <TextField
                  required
                  fullWidth
                  {...params}
                  size="small"
                  label=""
                  error={isCallDespositionRequired}
                  helperText={
                    isCallDespositionRequired ? "This field is required" : ""
                  }
                />
              )}
            />
          </Box>
          <Box
            sx={{
              padding: "10px",
            }}
          >
            <DialogContentText
              sx={{
                marginBottom: "5px",
              }}
            >
              Remark *
            </DialogContentText>
            <TextField
              size="small"
              fullWidth
              required
              onChange={(e) => {
                setRemark(e.target.value);
                if (e.target.value) {
                  setIsRemarkRequired(false);
                }
              }}
              onBlur={(event, val) => {
                if (!event?.target?.value) {
                  setIsRemarkRequired(true);
                } else {
                  setIsRemarkRequired(false);
                }
              }}
              id="outlined-multiline-static"
              // label="Multiline"
              multiline
              rows={4}
              error={isRemarkRequired}
              helperText={isRemarkRequired ? "This field is required" : ""}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleUpdate}
            disabled={!remark || !callDesposition ? true : false}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SalariedLeads;
