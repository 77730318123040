import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPointsConfig from "./apiEndPointsConfig";

const baseQuery = fetchBaseQuery({
  baseUrl: apiEndPointsConfig?.baseURL,
  Credential: "include",
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error && result?.error?.status === 401) {
    const refreshToken = localStorage.getItem("refressToken")
      ? localStorage.getItem("refressToken")
      : null;

    const refreshResult = await baseQuery(
      {
        url: apiEndPointsConfig.refreshToken,
        method: "POST",
        body: {
          data: {
            refreshToken: refreshToken,
          },
        },
        headers: {},
      },
      api,
      extraOptions
    );

    if (refreshResult?.data && !refreshResult.data.error) {
      localStorage.setItem(
        "accessToken",
        refreshResult.data?.data?.accessToken
      );
      args.headers.authorization = `Bearer ${refreshResult?.data?.data?.accessToken}`;
      result = await baseQuery(args, api, extraOptions);
    } else if (refreshResult.data.error) {
      localStorage.clear();
      window.location.href = "/";
    }
  }
  return result;
};

export const apiQueryServices = createApi({
  reducerPath: "apiQueryServices",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getApiCall: builder.query({
      query: (arg) => ({
        url: arg.endPoint,
        method: arg.method,
        headers: {
          "Access-Control-Allow-Origin": "*",
          authorization: localStorage.getItem("accessToken")
            ? `Bearer ${localStorage.getItem("accessToken")}`
            : "",
          ...arg.headers,
        },
        validateStatus: (response, result) => {
          if (response.status) {
          } else result = null;
          return result;
        },
      }),
    }),
    apiCall: builder.mutation({
      query: (arg) => ({
        url: arg.endPoint,
        method: arg.method,
        body: arg.data,
        headers: {
          "Access-Control-Allow-Origin": "*",
          authorization: localStorage.getItem("accessToken")
            ? `Bearer ${localStorage.getItem("accessToken")}`
            : "",
          ...arg.headers,
        },
      }),
    }),
  }),
});

export const { useGetApiCallQuery, useApiCallMutation } = apiQueryServices;
