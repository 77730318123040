import { createBrowserRouter, Navigate } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import MainLayout from "layout/MainLayout";
import Dsa from "views/DSA";
import DsaDashboard from "views/DsaDashboard";
import NotFound from "views/notFound/NotFound";
import ProtectedRoute from "./ProtectedRoute";

// ==============================|| ROUTING RENDER ||============================== //

// export default function ThemeRoutes() {
//     return useRoutes([MainRoutes, AuthenticationRoutes]);
// }

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dsa" replace />,
      },

      // {
      //   path: "/dashboard",
      //   element: (
      //     <ProtectedRoute>
      //       <Dashboard />
      //     </ProtectedRoute>
      //   ),
      // },
      // {
      //   path: "/settings",
      //   element: (
      //     <ProtectedRoute>
      //       <BasicTabs />
      //     </ProtectedRoute>
      //   ),
      // },
      // {
      //   path: "/leads",
      //   element: (
      //     <ProtectedRoute>
      //       <Leads />
      //     </ProtectedRoute>
      //   ),
      // },
      // {
      //   path: "/dropoff",
      //   element: (
      //     <ProtectedRoute>
      //       <DropOffPage />
      //     </ProtectedRoute>
      //   ),
      // },
      {
        path: "/dsa",
        element: (
          <ProtectedRoute>
            <Dsa />
          </ProtectedRoute>
        ),
      },
      {
        path: "/DSAdashboard",
        element: (
          <ProtectedRoute>
            <DsaDashboard />
          </ProtectedRoute>
        ),
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  AuthenticationRoutes,
]);
