import {
  CalendarViewMonthOutlined,
  DashboardOutlined,
} from "@mui/icons-material";
import { Box, Divider, Tab, Tabs } from "@mui/material";
import { useContext, useState } from "react";
import PageHeader from "ui-component/pageHeader";
import Icon from "@mdi/react";
import {
  mdiAccountBadgeOutline,
  mdiAccountCashOutline,
  mdiNewBox,
  mdiProgressClock,
} from "@mdi/js";
import { TabContext, TabPanel } from "@mui/lab";

import SalariedLeads from "views/leads/SalariedLeads";
import SelfEmpLeads from "views/leads/SelfEmpLeads";
import { useTabContext } from "context/TabContext";

function Dsa() {
  const { tabValue, handleChange, secondTabValue, handleSecondTabChange } =
    useTabContext();

  // const [tabValue, setTabValue] = useState(1);
  // const [secondTabValue, setSecondTabValue] = useState(1);

  const tabIconColorActive = "#97B7F9";
  const tabTextColorActive = "#3E7DFD";
  const tabIconColorInactive = "#525252";
  const tabTextColorInactive = "#525252";

  return (
    <>
      <PageHeader
        title={"DSA"}
        helpText={"Direct Selling Agent"}
        isAvailableShow={false}
      />
      <Box pl={2.5} sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabValue}
          onChange={(e, newval) => {
            handleChange(Number(newval));
          }}
          aria-label="lab API tabs example"
          sx={{
            p: 0,
            m: 0,
            height: "15px",
            // "& .MuiTabsIndicator": {
            //   borderRadius: "4px 4px 0px 0px",
            //   borderBottom: "3px solid #3E7DFD",
            // },
          }}
        >
          <Tab
            icon={<Icon path={mdiAccountBadgeOutline} size={1} />}
            iconPosition="start"
            label="Salaried"
            value={1}
            style={{
              color: tabValue === 1 ? tabTextColorActive : tabTextColorInactive,
              leadingTrim: "both",
              textEdge: "cap",
              fontFamily: "Inter",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: tabValue === 1 ? 600 : 400,
              lineHeight: "normal",
              padding: 0,
              margin: 0,
              marginLeft: "15px",
              marginTop: "-10px",
              "& .MuiSelected": {
                borderBottom: "3px solid #3E7DFD",
                borderWidth: "2px",
                borderRadius: "4px 4px 0px 0px",
              },
              "& .MuiTabsIndicator": {
                borderRadius: "4px 4px 0px 0px",
                borderBottom: "3px solid #3E7DFD",
              },
            }}
          />

          <Tab
            icon={<Icon path={mdiAccountCashOutline} size={1} />}
            iconPosition="start"
            label="Self Employed"
            value={2}
            style={{
              color: tabValue === 2 ? tabTextColorActive : tabTextColorInactive,
              leadingTrim: "both",
              textEdge: "cap",
              fontFamily: "Inter",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: tabValue === 2 ? 600 : 400,
              lineHeight: "normal",
              padding: 0,
              margin: 0,
              marginLeft: "15px",
              marginTop: "-10px",
              "&.MuiSelected": {
                borderBottom: "3px solid #3E7DFD",
                borderWidth: "2px",
                borderRadius: "4px 4px 0px 0px",
              },
              "& .MuiTabsIndicator": {
                borderRadius: "4px 4px 0px 0px",
                borderBottom: "3px solid #3E7DFD",
              },
            }}
          />
        </Tabs>
      </Box>

      <TabContext value={secondTabValue}>
        <Box pl={2.5} sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={secondTabValue}
            onChange={(e, newval) => {
              handleSecondTabChange(Number(newval));
            }}
            aria-label="lab API tabs example"
            sx={{
              p: 0,
              m: 0,
              height: "15px",
              "& .MuiTabsIndicator": {
                borderRadius: "4px 4px 0px 0px",
                borderBottom: "3px solid #3E7DFD",
              },
            }}
          >
            <Tab
              icon={<Icon path={mdiNewBox} size={1} />}
              iconPosition="start"
              label="New"
              value={1}
              style={{
                color:
                  secondTabValue === 1
                    ? tabTextColorActive
                    : tabTextColorInactive,
                leadingTrim: "both",
                textEdge: "cap",
                fontFamily: "Inter",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: secondTabValue === 1 ? 600 : 400,
                lineHeight: "normal",
                padding: 0,
                margin: 0,
                marginLeft: "15px",
                marginTop: "-10px",
                "& .MuiSelected": {
                  borderBottom: "3px solid #3E7DFD",
                  borderWidth: "2px",
                  borderRadius: "4px 4px 0px 0px",
                },
                "& .MuiTabsIndicator": {
                  borderRadius: "4px 4px 0px 0px",
                  borderBottom: "3px solid #3E7DFD",
                },
              }}
            />

            <Tab
              icon={<Icon path={mdiProgressClock} size={1} />}
              iconPosition="start"
              label="Inprogress"
              value={2}
              style={{
                color:
                  secondTabValue === 2
                    ? tabTextColorActive
                    : tabTextColorInactive,
                leadingTrim: "both",
                textEdge: "cap",
                fontFamily: "Inter",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: secondTabValue === 2 ? 600 : 400,
                lineHeight: "normal",
                padding: 0,
                margin: 0,
                marginLeft: "15px",
                marginTop: "-10px",
                "&.MuiSelected": {
                  borderBottom: "3px solid #3E7DFD",
                  borderWidth: "2px",
                  borderRadius: "4px 4px 0px 0px",
                },
                "& .MuiTabsIndicator": {
                  borderRadius: "4px 4px 0px 0px",
                  borderBottom: "3px solid #3E7DFD",
                },
              }}
            />
          </Tabs>
        </Box>
        <Divider />
        <Box sx={{ paddingLeft: "1rem" }}>
          <TabPanel value={1} sx={{ paddingRight: "0" }}>
            <SalariedLeads
              employmnetStatus={tabValue === 1 ? "Salaried" : "Self Employed"}
              progressStatus={secondTabValue === 1 ? "new" : "in progress"}
            />
          </TabPanel>
          <TabPanel value={2} sx={{ paddingRight: "0" }}>
            <SalariedLeads
              employmnetStatus={tabValue === 1 ? "Salaried" : "Self Employed"}
              progressStatus={secondTabValue === 1 ? "new" : "in progress"}
            />
          </TabPanel>
        </Box>
      </TabContext>
    </>
  );
}

export default Dsa;
