import React, { useState, useEffect } from "react";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  Divider,
  Drawer,
  useTheme,
  Typography,
  Toolbar,
  ListItem,
  Tooltip,
  ListItemText,
  SettingIcon,
  useNavigate,
} from "../../../collections/Imports";
import { _nav } from "./nav/_nav";
import { Button, ListSubheader } from "@mui/material";

function SideBar() {
  const [isActive, setIsActive] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(0);
  const navigate = useNavigate();

  const handleMenuItemClick = (index, to) => {
    setActiveMenuItem(index);
  };

  const handlePopstate = () => {
    const currentPath = window.location.pathname;
    const activeIndex = _nav.findIndex((item) => item.to === currentPath);

    if (activeIndex !== -1) {
      setIsActive(true);
      setActiveMenuItem(activeIndex);
    } else {
      setIsActive(false);
      setActiveMenuItem(0);
    }
  };

  useEffect(() => {
    handlePopstate();

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const textActiveStyle = {
    color: "#fff",
    fontSize: "10px",
    "& .MuiListItemText-primary": {
      color: "#fff",
    },
  };
  const textSimpleStyle = {
    color: "#000",
    fontSize: "10px",
  };

  const activeStyle = {
    width: "233px",
    height: "40px",
    margin: "0px 5px 5px 5px",
    background: "#2A4BC0",
    color: "white",
    gap: "0px",
    borderRadius: "5px",
  };

  const simpleStyle = {
    minWidth: 0,
    width: "233px",
    height: "40px",
    margin: "0px 5px 5px 5px",
    gap: "0px",
    background: "#fff",
    color: "#000",
    borderRadius: "0px",
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 200,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 200,
          boxSizing: "border-box",
          zIndex: "-1",
        },
      }}
    >
      <Toolbar sx={{ position: "relative", width: "195px", marginTop: 6 }}>
        <Box
          sx={{
            overflow: "hidden",
            backgroundColor: "#fff",
            minWidth: "100%",
          }}
        >
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                DSA Management
              </ListSubheader>
            }
          >
            {_nav.map((sidebarmenus, index) => (
              <ListItem
                key={sidebarmenus.name + index}
                disablePadding
                className="sideMenuItem"
                onClick={() => {
                  navigate(sidebarmenus.to);
                }}
              >
                <ListItemButton
                  component="button"
                  key={sidebarmenus.name}
                  sx={activeMenuItem === index ? activeStyle : simpleStyle}
                  onClick={() => {
                    debugger;
                    navigate(sidebarmenus.to);
                  }}
                >
                  {/* <Tooltip title={sidebarmenus.name}> */}
                  <ListItemIcon className="sideNavIcon">
                    {sidebarmenus?.icon && (
                      <img
                        className="menu-icon"
                        alt="icon-img"
                        src={sidebarmenus.icon}
                      />
                    )}
                  </ListItemIcon>
                  {/* </Tooltip> */}
                  <ListItemText
                    primary={sidebarmenus.name}
                    sx={
                      activeMenuItem === index
                        ? textActiveStyle
                        : textSimpleStyle
                    }
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Toolbar>
      <Divider />
    </Drawer>
  );
}

export default SideBar;
