import {
  DashboardIcon,
  LeadsIcon,
  SettingIcon,
} from "../../../../collections/Imports.js";

export const _nav = [
  // {
  //   _tag: "MenuItem",
  //   name: "Settings",
  //   to: "/settings",
  //   icon: SettingIcon,
  // },
  {
    _tag: "MenuItem",
    name: "DSA",
    to: "/dsa",
    icon: LeadsIcon,
  },
  // {
  //   _tag: "MenuItem",
  //   name: "DSADashboard",
  //   to: "/DSAdashboard",
  //   icon: DashboardIcon,
  // },
];
