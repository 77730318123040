import React, { createContext, useState } from "react";
const AppContext = createContext();
const DashBoardContextProvider = ({ children }) => {
  const [numberDetails, setNumberDetails] = useState();
  const [customerNumber, setCustomerNumber] = useState();
  const [prevURL, setPrevURL] = useState("");
  const [summaryDetails, setSummaryDetails] = useState();
  const [summaryColumnDetails, setSummaryColumnDetails] = useState();
  const [lastDisposition, setLastDisposition] = useState();
  return (
    <AppContext.Provider
      value={{
        numberDetails,
        setNumberDetails,
        prevURL,
        setPrevURL,
        summaryDetails,
        setSummaryDetails,
        summaryColumnDetails,
        setSummaryColumnDetails,
        customerNumber,
        setCustomerNumber,
        lastDisposition,
        setLastDisposition,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export { AppContext, DashBoardContextProvider };
