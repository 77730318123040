import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Switch,
  Divider,
  styled,
} from "../../collections/Imports";

const CustomizeSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 56,
  height: 30,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(29px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#E4E4E4",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#abc465",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: "grey",
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.3,
    },
    "&:not(.Mui-checked) .MuiSwitch-thumb": {
      color: "red",
    },
    "&.Mui-checked .MuiSwitch-thumb": {
      color: "green",
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E4E4E4",
    opacity: 1,
    "&:before, &:after": {
      display: "inline-block",
      position: "absolute",
      color: "black",
      textAlign: "center",
      fontSize: "12px",
    },
    "&:before": {
      content: '"in"',
      left: 8,
      top: 4,
      textAlign: "left",
      color: " #777",
      fontSize: "15px",
    },
    "&:after": {
      content: '"out"',
      right: 4.2,
      top: 4,

      fontSize: "15px",
      textAlign: "right",
      color: " #777",
    },
  },
}));

const PageHeader = ({ title, onAvailable, isAvailableShow }) => {
  const [isAvailable, setIsAvailable] = useState(false);
  const [isSession, setIsSession] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user) {
      setIsSession(true);
    } else {
      setIsSession(false);
    }
  }, [user]);

  useEffect(() => {
    if (onAvailable !== undefined && onAvailable !== null) {
      onAvailable(isAvailable);
    }
  }, [isAvailable]);
  return (
    <Box sx={{ bgcolor: "#eef2f6", pt: "8px" }}>
      <Stack
        direction="row"
        sx={{
          px: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "inline-flex" }}>
          <Typography
            sx={{
              color: "#000000",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontFamily: "Inter",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "34px",
              letterSpacing: "-0.02px",
              paddingLeft: "5px",
            }}
          >
            {title}
            {/* <span
            style={{
              fontSize: "24px",
              color: "#0E356C",
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            {title}
          </span> */}
          </Typography>
        </Box>
        {isAvailableShow !== false && (
          <Box
            sx={{
              width: "215px",
              height: "41px",
              borderRadius: "5px",
              backgroundColor: "#0E356C",
              color: "#FFFFFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
            }}
          >
            {" "}
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: "600",
                letterSpacing: "-0.02rem",
                textAlign: "left",
              }}
            >
              Available
            </Typography>{" "}
            <CustomizeSwitch
              disabled={isSession}
              checked={isSession}
              onChange={() => {
                setIsAvailable(!isAvailable);
                setIsSession(true);
              }}
            />
          </Box>
        )}
      </Stack>
      <Divider sx={{ width: "100%", pt: "8px" }} />
    </Box>
  );
};

export default PageHeader;
