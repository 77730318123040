const apiEndPointsConfig = {
  // BASE URL
  baseURL: process.env.REACT_APP_BASE_API_URL,

  // public
  login: "/lds/public/agent-login",
  refreshToken: "/lds/public/refresh-token",

  //getNumber
  getNumber: "/lds/crm/get-number",
  getNumberDetails: "/lds/crm/get-number-details",

  //dispositionApi
  getDisposition: "/lds/crm/get-call-dispositions",
  getDispositionList: "/lds/crm/get-call-dispositions",

  //summaryApi
  getSummaryDetails: "/lds/crm/disposition-summary",

  getUpdateCRM: "/lds/crm/update-crm-status",
  getLastDisposition: "/lds/crm/last-disposition-summary",
  changePassword: "/lds/crm/change-password",
  sendEmail: "/lds/crm/send-email",
  sendSMS: "/lds/crm/send-sms",
  // getStatus: "/lds/status/get-status",
  // createStatus: "/lds/status/create-status",
  // misupload: "/lds/uploads/mis-upload",
  // updateStatus: "/lds/status/update-status",

  //leasds
  getSelfEmpLeads: "/lds/crm//get-leads",
  getCallDespostionList: "/lds/crm/get-call-dispositions",
  updateCallDesposition: "/lds/crm/update-status",
};

export default apiEndPointsConfig;
